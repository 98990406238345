import React, { useEffect, lazy } from "react";
import {
  createBrowserRouter,
  Outlet,
  Navigate,
  RouterProvider,
  useLocation,
} from "react-router-dom";
// import RootLayout from "./RootLayout";

import Homepage from "./OSCI/src/pages/homepage";
import StoryBook from "./shared/storybook";
import TGBreadCrumb from "./shared/tg-breadcrumb";
import { ResetPassword } from "./OSCI/src/pages/reset-password";
import { useTranslation } from "react-i18next";
import TGLoader from "./shared/tg-loader";
import SignUp from "./OSCI/src/pages/signup";
import { removeLangFromRoute } from "./utils/helper";
import UnlockAccountForm from "./OSCI/src/component/unlock-account-content/unlock-account-form";
import { ResetUnlockBanner } from "./OSCI/src/pages/reset-unlock/banner";
import { Success } from "./OSCI/src/pages/reset-unlock/success";
import FeedbackContent from "./OSCI/src/component/feedback-content";
import ParentConsent from "./OSCI/src/pages/signup/parent-consent";
import EnrollSuccess from "./OSCI/src/pages/signup/enroll-success";
import Header from "./OSCI/src/component/header";
import { Footers } from "./OSCI/src/component/footers";
import RedemptionHeader from "./OSCI/src/component/redemption/redemption-header";
import { ResetCrm } from "./OSCI/src/pages/reset-password-CRM/index";
import { CRMSuccess } from "./OSCI/src/pages/signup/crm-success";
import { NewsletterUnsubscribe } from "./OSCI/src/pages/newsletter-unsubscribe";
import BookFlightStatus from "./OSCI/src/pages/book-flight-status";
import { NewsletterSubscribe } from "./OSCI/src/pages/newsletter-subscribe";
import FlightStatusDetail from "./OSCI/src/component/booking-widget/flight-status/flight-status-detail";
import FlightStatusRouteDetail from "./OSCI/src/component/booking-widget/flight-status/flight-status-route-detail";
import ProfileContactInfo from "./OSCI/src/component/profile-contact-info/index";
import CrmProfile from "./OSCI/src/pages/myprofile/crm-profile";
import { MyProfile } from "./OSCI/src/pages/myprofile/rop-profile/profile-component";
import FlightStatusUpcomingTripsDetails from "./OSCI/src/component/booking-widget/flight-status/flight-status-upcoming-trips-details";
import BookFlightSchedule from "./OSCI/src/pages/flight-schedule";
import SocialSignIn from "./shared/login-widget/sign-in/social-signin";
import AddNominee from "./OSCI/src/pages/myprofile/rop-profile/nominee-info/add-nominee";
import WallettestComponent from "./OSCI/src/component/wallet-test";
import ReviewPassenger from "./OSCI/src/component/redemption/review-passenger";
import ReviewInformation from "./OSCI/src/component/redemption/review-information";
import { RopChangePassword } from "./OSCI/src/pages/rop-change-password";
import MileageStatement from "./OSCI/src/pages/mileage-statement";
import EReceiptPage from "./OSCI/src/pages/e-Receipt";
import { ErrorPage } from "./OSCI/src/component/booking-widget/e-Receipt/errorPage/index";
import SuccessPage from "./OSCI/src/component/booking-widget/e-Receipt/success";
import SelectFlight from "./OSCI/src/component/redemption/select-flight";
import { useDispatch } from "react-redux";
import { setError } from "./OSCI/src/slice/errorModalSlice";
import TGGenericErrorDialog from "./shared/tg-generic-error-dialog";
import RedemptionSuccess from "./OSCI/src/component/redemption/redemption-success";
import { MyTrips } from "./OSCI/src/pages/mytrips";
import MobFlightDetails from "./OSCI/src/pages/mytrips/signeduser/mob-flight-details";
import { MemberStatusCalculator } from "./OSCI/src/pages/member-status-calculator";
import { Suspense } from "react";
import Headers from "./DAPI/src/component/headers";
import TGFullScreenError from "./shared/tg-full-screen-error";
import QualifyingMilesCalculator from "./OSCI/src/pages/qualifying-miles-calculator";
import GiftMiles from "./OSCI/src/component/gift-miles";
import Footer from "./DAPI/src/component/footer";
import RedemptionError from "./OSCI/src/component/redemption/redemption-error"
import CancelCheckin from "./DAPI/src/component/check-in/cancel-checkin";

const RedirectionPopup: React.FC<any> = (props: any) => {
  const { isGuestMode } = props;
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  useEffect(() => {
    dispatch(
      setError({
        message: isGuestMode
          ? "Please logout first to access this Page, You will be redirecting to Homepage in 3 Seconds"
          : "Please Sign in first to access this Page",
        heading: "Unauthorised Access",
        buttonText: !isGuestMode
          ? i18n.t("label_enroll_sign_in")
          : i18n.t("profile_cancel"),
        errCode: 0,
        isLoginReq: !isGuestMode,
      })
    );
    setTimeout(() => {
      if (isGuestMode) {
        window.location.replace(window.location.origin);
      }
    }, 3000);
  }, []);
  return <></>;
};

const PrivateRoute: React.FC<any> = (props: any) => {
  const { Component, flow } = props;
  let isAuthenticated = false;
  if (
    sessionStorage?.getItem("accesstoken") &&
    ((flow === "rop" && sessionStorage?.getItem("isROPLoggedIn")) ||
      (flow === "crm" && sessionStorage?.getItem("isCRMLoggedIn")))
  ) {
    isAuthenticated = true;
  }
  return isAuthenticated ? (
    <Component />
  ) : (
    <RedirectionPopup isGuestMode={false} />
  );
};

//Will be required in case if we need to restrict user to access the page being logged in.

// const GuestRoute: React.FC<any> = (props: any) => {
//   const { Component } = props;
//   let isGuest = false;
//   if (
//     !sessionStorage?.getItem("accesstoken") &&
//     !sessionStorage?.getItem("isROPLoggedIn") &&
//     !sessionStorage?.getItem("isCRMLoggedIn")
//   ) {
//     isGuest = true;
//   }
//   return isGuest ? <Component /> : <RedirectionPopup isGuestMode={true} />;
// };

const RootLayout: React.FC = () => {
  const location = useLocation();
  const showBreadCrumb = removeLangFromRoute(location?.pathname) !== "/";
  const queryParams = new URLSearchParams(location?.search);
  const channel = queryParams.get("channel");
  return (
    <>
      {channel !== "app" && <Header />}
      <main>
        {showBreadCrumb && channel !== "app" && <TGBreadCrumb />}
        {/* {!showBreadCrumb && <BottomNavigationComponent />} */}
        <Outlet />
        <TGGenericErrorDialog />
      </main>
      {channel !== "app" && <Footers />}
    </>
  );
};

const RedemptionLayout: React.FC = () => {
  return (
    <>
      <RedemptionHeader />
      <main>
        <Outlet />
        <TGGenericErrorDialog />
      </main>
    </>
  );
};

const CheckinLayout: React.FC = () => {
  return (
    <>
      <Headers />
      <main>
        <Outlet />
        <TGGenericErrorDialog />
      </main>
      <Footer />
    </>
  );
};

const CheckinSelectPassenger = lazy(
  () => import("./DAPI/src/component/check-in/select-passengers/index")
);
const CheckinPassengerDetails = lazy(
  () => import("./DAPI/src/component/check-in/passenger-details/index")
);
const CheckinAdditionalServices = lazy(
  () => import("./DAPI/src/component/check-in/additional-services/index")
);
const CompleteCheckin = lazy(
  () => import("./DAPI/src/component/check-in/complete-checkin/index")
);

const Main: React.FC = () => {
  const { i18n } = useTranslation();
  let router;
  if (i18n.language === "index") {
    router = createBrowserRouter([
      {
        path: "/",
        element: <TGLoader />,
      },
    ]);
  } else
    router = createBrowserRouter([
      {
        path: "/",
        element: <RootLayout />,
        children: [
          { path: "/", element: <Navigate to={`${i18n.language}`} /> },
          { path: `/${i18n.language}`, element: <Homepage /> },
          { path: `/${i18n.language}/storybook`, element: <StoryBook /> },
          {
            path: `/${i18n.language}/rop/resetpassword`,
            element: <ResetPassword />,
          },
          {
            path: `/${i18n.language}/resetunlockcontainer`,
            element: <ResetUnlockBanner />,
          },
          {
            path: `/${i18n.language}/resetunlockSuccess`,
            element: <Success />,
          },
          {
            path: `/${i18n.language}/signup`,
            element: <SignUp />,
          },
          {
            path: `/${i18n.language}/unlockaccount`,
            element: <UnlockAccountForm />,
          },
          {
            path: `/${i18n.language}/resetpassword`,
            element: <ResetCrm />,
          },
          {
            path: `/${i18n.language}/myprofile`,
            element: <PrivateRoute Component={MyProfile} flow="rop" />,
          },
          {
            path: `/${i18n.language}/member/myprofile`,
            element: <PrivateRoute Component={CrmProfile} flow="crm" />,
          },
          {
            path: `/${i18n.language}/myprofile/changepassword`,
            element: <RopChangePassword />,
          },
          {
            path: `/${i18n.language}/feedback`,
            element: <FeedbackContent />,
          },
          {
            path: `/${i18n.language}/enrolment/parentconsent`,
            element: <ParentConsent />,
          },
          {
            path: `/${i18n.language}/enrolment/success`,
            element: <EnrollSuccess />,
          },
          {
            path: `/${i18n.language}/enrollment`,
            element: <SignUp />,
          },
          {
            path: `/${i18n.language}/crmenrollment/success`,
            element: <CRMSuccess />,
          },
          {
            path: `/${i18n.language}/unsubscribe`,
            element: <NewsletterUnsubscribe />,
          },
          {
            path: `/${i18n.language}/flightstatus`,
            element: <BookFlightStatus />,
          },
          {
            path: `/${i18n.language}/subscribe`,
            element: <NewsletterSubscribe />,
          },
          {
            path: `/${i18n.language}/flightstatus/flightstatusdetail`,
            element: <FlightStatusDetail />,
          },
          {
            path: `/${i18n.language}/flightstatus/flightstatusroutedetail`,
            element: <FlightStatusRouteDetail />,
          },
          {
            path: `/${i18n.language}/profileContactInfo`,
            element: <PrivateRoute Component={ProfileContactInfo} flow="rop" />,
          },
          {
            path: `/${i18n.language}/flightstatus/flightstatusupcomingtripsdetail`,
            element: <FlightStatusUpcomingTripsDetails />,
          },
          {
            path: `/${i18n.language}/flightschedule`,
            element: <BookFlightSchedule />,
          },

          {
            path: `/${i18n.language}/myprofile/memberstatuscalculator`,
            element: <MemberStatusCalculator />,
          },
          {
            path: `/socialsignin`,
            element: (
              <SocialSignIn
                showModal={() => {}}
                handleGenericError={() => {}}
              />
            ),
          },
          {
            path: `/wallettest`,
            element: <WallettestComponent />,
          },
          {
            path: `/${i18n.language}/myprofile/add-nominee`,
            element: <PrivateRoute Component={AddNominee} flow="rop" />,
          },
          {
            path: `/${i18n.language}/myprofile/mileage-statement`,
            element: <PrivateRoute Component={MileageStatement} flow="rop" />,
          },
          {
            path: `/${i18n.language}/e-Receipt`,
            element: <EReceiptPage />,
          },
          {
            path: `/${i18n.language}/e-Receipt/success`,
            element: <SuccessPage />,
          },
          {
            path: `/${i18n.language}/e-Receipt/error`,
            element: <ErrorPage />,
          },
          {
            path: `/${i18n.language}/my-trips`,
            element: <MyTrips />,
          },
          {
            path: `/${i18n.language}/my-trips/past-trips`,
            element: <MyTrips />,
          },
          {
            path: `/${i18n.language}/my-trips-details/:destination`,
            element: <MobFlightDetails />,
          },
          {
            path: `/${i18n.language}/myprofile/memberstatuscalculator/qualifyingmilescalculator`,
            element: <QualifyingMilesCalculator />,
          },
          {
            path: `/${i18n.language}/myprofile/gift-miles`,
            element: <GiftMiles />,
          },
          {
            path: "*",
            element: (
              <Navigate
                to={
                  !window.location.pathname.includes(i18n.language)
                    ? `${i18n.language}${window.location.pathname}`
                    : ""
                }
              />
            ),
          },
          // {
          //   path: `/${i18n.language}/redemption`,
          //   element: <Redemption />,
          // },
        ],
      },
      {
        path: `/${i18n.language}/redemption`,
        element: <RedemptionLayout />,
        children: [
          {
            path: `/${i18n.language}/redemption/selectflight`,
            element: <PrivateRoute Component={SelectFlight} flow="rop" />,
          },
          {
            path: `/${i18n.language}/redemption/passengers`,
            element: <PrivateRoute Component={ReviewPassenger} flow="rop" />,
          },
          {
            path: `/${i18n.language}/redemption/review`,
            element: <PrivateRoute Component={ReviewInformation} flow="rop" />,
          },
          {
            path: `/${i18n.language}/redemption/success`,
            element: <PrivateRoute Component={RedemptionSuccess} flow="rop" />,
          },
          {
            path: `/${i18n.language}/redemption/error`,
            element: <PrivateRoute Component={RedemptionError} flow="rop" />,
          },
          {
            path: "*",
            element: (
              <Navigate
                to={
                  !window.location.pathname.includes(i18n.language)
                    ? `${i18n.language}${window.location.pathname}`
                    : ""
                }
              />
            ),
          },
        ],
      },
      {
        path: `/${i18n.language}/checkin`,
        element: (
          <Suspense fallback={<div></div>}>
            <CheckinLayout />
          </Suspense>
        ),
        children: [
          {
            path: `/${i18n.language}/checkin/select-passengers`,
            element: <CheckinSelectPassenger />,
          },
          {
            path: `/${i18n.language}/checkin/passenger-details`,
            element: <CheckinPassengerDetails />,
          },
          {
            path: `/${i18n.language}/checkin/additional-services`,
            element: <CheckinAdditionalServices />,
          },
          {
            path: `/${i18n.language}/checkin/complete-checkin`,
            element: <CompleteCheckin />,
          },
          {
            path: `/${i18n.language}/checkin/cancel-checkin`,
            element: <CancelCheckin />,
          },
        ],
      },
    ]);

  return <RouterProvider router={router} />;
};

export default Main;
